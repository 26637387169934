import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Color from "color";

import { isStoreHomePage } from "../../../utils/utils";
import Delivery from "./MyOrder/Delivery";
// import DeliveryDateTimePicker from "./MyOrder/DeliveryDateTimePicker";
import PromoBanners from "./promotions/PromoBanners";
import { useDispatch, useSelector } from "react-redux";
import { addCompletedPage } from "../../routes/redux/actions";
import useRouteNavigation from "../../../hooks/RouteHooks/useRouteNavigation";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import firebase from "firebase";
import { setLocalStore } from "../../../utils/storage/localStorage";
import DeliveryDateTimePickerNew from "./MyOrder/DeliveryDateTimePickerNew";
import DeliveryDateTimePicker from "./MyOrder/DeliveryDateTimePicker";

const useStyles = makeStyles((theme) => ({
  SelectedOptionsDialogWrapper: {
    "& .MuiDialog-paperFullWidth": {
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
    },

    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  },
  continueBtn: {
    "&.MuiButton-root": {
      backgroundColor: theme.palette.background.accentColor,
      color: theme.palette.text.accentTextColor,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    "&:hover": {
      backgroundColor: Color(theme.palette.background.accentColor)
        .lighten(0.5)
        .hex(),
    },
  },
}));

const SelectedOptionsDialog = ({
  isDineIn,
  cookieBlockAlertOpen,
  showFullscreenAlert,
}) => {
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const { isFetchingLoading } = useSelector((state) => state.auth);
  const timePickerRef = useRef();

  const classes = useStyles();
  const isShopPage = isStoreHomePage();
  const dispatch = useDispatch();
  const { navigateRoute } = useRouteNavigation();
  const { params } = useRouteMatch();

  useEffect(() => {
    const isShowOptionsDialog = () => {
      if (isDineIn) return setOpen(false);
      if (cookieBlockAlertOpen) return setOpen(false);
      if (showFullscreenAlert) return setOpen(false);
      return setOpen(isShopPage);
    };

    isShowOptionsDialog();

    return () => {
      setOpen(false);
      setSelected(true);
    };
  }, [isDineIn, cookieBlockAlertOpen, showFullscreenAlert, isShopPage]);

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const handleOnContinueClick = () => {
    const isValid = timePickerRef.current.validateTimeSelected();
    if (isValid) {
      setOpen(false);
    }
  };

  const handleOrderNow = () => {
    const pathname = `/location/${params.locationId}/my-orders`;
    dispatch(addCompletedPage("/my-orders"));
    return navigateRoute(pathname);
  };

  useEffect(() => {
    const onAuthStateChange = async () => {
      const authFirebase = firebase.auth();
      await authFirebase.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          firebaseUser
            .getIdToken(true)
            .then((idToken) => {
              setLocalStore("emailVerified", firebaseUser.emailVerified);

              setIsLoading(false);
              setIsAuth(true);
            })
            .catch(() => {
              setIsLoading(false);
              setIsAuth(false);
            });
        } else {
          setIsLoading(false);
          setIsAuth(false);
        }
      });
    };
    onAuthStateChange();
  }, [dispatch]);

  return (
    <>
      {!open && isShopPage && !cookieBlockAlertOpen && !showFullscreenAlert && (
        <PromoBanners />
      )}
      <Dialog
        classes={{ root: classes.SelectedOptionsDialogWrapper }}
        open={open && !selected}
        maxWidth="xs"
        fullWidth
        onClose={(e, reason) => handleClose(e, reason)}
        aria-labelledby="options-dialog"
        aria-describedby="options-dialog-content"
      >
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          style={{ fontWeight: "bold" }}
        >
          Select you order type and time
        </Typography>
        <Delivery isModal />
        <DeliveryDateTimePickerNew ref={timePickerRef} isModal />
        {isAuth ? (
          <div style={{ margin: "32px 16px 8px 16px", display: "flex" }}>
            <Grid container>
              <Grid item xs={7}>
                <Button
                  onClick={handleOnContinueClick}
                  variant="contained"
                  color="secondary"
                  classes={{ root: classes.continueBtn }}
                  fullWidth
                  disabled={isFetchingLoading}
                  style={{ height: 48 }}
                >
                  Start a New Order
                </Button>
              </Grid>
              <Grid item xs={5} style={{ paddingLeft: "6px" }}>
                <Button
                  onClick={handleOrderNow}
                  variant="contained"
                  color="secondary"
                  classes={{ root: classes.continueBtn }}
                  fullWidth
                  disabled={isFetchingLoading}
                  style={{
                    height: 48,
                    paddingRight: "12px",
                    paddingLeft: "12px",
                  }}
                >
                  Re-Order Usual
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ margin: "32px 16px 8px 16px" }}>
            <Button
              onClick={handleOnContinueClick}
              variant="contained"
              color="secondary"
              classes={{ root: classes.continueBtn }}
              fullWidth
              disabled={isLoading}
              style={{ height: 48 }}
            >
              {isLoading ? <CircularProgress size={24} /> : "Continue"}
            </Button>
          </div>
        )}
      </Dialog>
    </>
  );
};

SelectedOptionsDialog.propTypes = {};
SelectedOptionsDialog.defaultProps = {};

export default SelectedOptionsDialog;
