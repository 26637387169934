import React, { useEffect } from "react";

import {
  Box,
  Divider,
  Hidden,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";

import {
  loginUserError,
  loginUserSuccess,
  setIsOpenLoginModal,
  setIsOpenRewardModal,
} from "../../../../redux/actions/auth";
import firebase from "../../../../utils/firebase";
import { setLocalStore } from "../../../../utils/storage/localStorage";
import useRouteNavigation from "../../../../hooks/RouteHooks/useRouteNavigation";
import { addCompletedPage } from "../../../routes/redux/actions";

const useStyles = makeStyles((theme) => ({
  userAccount: {
    height: 40,
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(0, 1),
    padding: theme.spacing(1, 0),
    alignItems: "center",
  },
}));

const UserAccount = () => {
  const classes = useStyles();

  const auth = useSelector((state) => state.auth);
  const { params } = useRouteMatch();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { navigateRoute } = useRouteNavigation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setIsOpenLoginModal(true));
    dispatch(setIsOpenRewardModal(false));
  };

  useEffect(() => {
    const onAuthStateChange = async () => {
      // dispatch(loginUserPending(""));
      const authFirebase = firebase.auth();
      await authFirebase.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          firebaseUser
            .getIdToken(true)
            .then((idToken) => {
              setLocalStore("emailVerified", firebaseUser.emailVerified);

              // if (!isOpenLoginModal && firebaseUser.emailVerified) {
              //   if (isActiveLoyalty?.isActive) {

              //   } else {
              //     dispatch(
              //       fetchRewardDetailsListSuccess({
              //         locationId: params.locationId,
              //         points: [],
              //       }),
              //     );
              //   }

              //   logInfo({
              //     message: `Auth state changed: authentication success | user ${firebaseUser.uid}}`,
              //   });
              // }
              dispatch(
                loginUserSuccess(firebaseUser.displayName, firebaseUser.email),
              );
              setLocalStore("idToken", idToken);
              setLocalStore("userId", firebaseUser.uid);
              setLocalStore("photoURL", firebaseUser?.photoURL);
            })
            .catch(() => {
              dispatch(loginUserError("Error login user"));
            });
        } else {
          dispatch(loginUserError("Error login user"));
        }
      });
    };
    onAuthStateChange();
  }, [dispatch]);

  const handleOrderNow = () => {
    const pathname = `/location/${params.locationId}/my-orders`;
    dispatch(addCompletedPage("/my-orders"));
    return navigateRoute(pathname);
  };
  const theme = useTheme();
  return (
    <Box className={classes.userAccount}>
      <div
        onClick={handleClick}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <AccountCircleIcon style={{ marginRight: 5 }} fontSize="large" />
        <Typography>Hi {auth.displayName || "Guest"} </Typography>
      </div>
      <Hidden mdUp>
        <Divider
          orientation="vertical"
          style={{
            backgroundColor: theme.palette.background.boarderColor,
            marginTop: "2px",
            marginBottom: "2px",
            width: "1px",
            marginRight: "6px",
            marginLeft: "6px",
            height: "20px",
          }}
        />
      </Hidden>
      <div>
        {isAuthenticated && (
          <Typography
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "end",
            }}
            onClick={handleOrderNow}
          >
            My Orders
          </Typography>
        )}
      </div>
    </Box>
  );
};

UserAccount.propTypes = {};
UserAccount.defaultProps = {};

export default UserAccount;
