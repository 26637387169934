import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { logError } from "../../../../../services/logging";
import { useForm } from "react-hook-form";
import TextFieldDefault from "../../../../common/textFields/TextFieldDefault";
import firebase from "../../../../../utils/firebase";
import StickyButton from "../../../../common/buttons/StickyButton";
import { RegistrationNew } from "../../../../../utils/RegistrationNew";

const useStyles = makeStyles((theme) => ({
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));

const RegistrationPageNew = ({
  handleChangeLoginStatus,
  setAuthError,
  setCustomerDetails,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  const { register, errors, trigger } = useForm();
  const { isAuthenticated, isOpenLoginModal } = useSelector(
    (state) => state.auth,
  );

  const classes = useStyles();
  useEffect(() => {
    setAuthError("");
  }, [isOpenLoginModal]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const trimValues = useCallback(async () => {
    setCustomer({
      firstName: customer?.firstName?.trim(),
      lastName: customer?.lastName?.trim(),
      confirmPassword: customer.confirmPassword?.trim(),
      email: customer?.email?.trim(),
      password: customer?.password?.trim(),
    });
  }, [customer]);

  const handleContinue = async () => {
    setAuthError("");
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    RegistrationNew(
      setIsLoading,
      customer,
      setAuthError,
      dispatch,
      params,
      logError,
      setCustomerDetails,
    );
  };

  const handleChangeEmail = (e) => {
    const email = e.target.value;
    setCustomer((prev) => ({ ...prev, email }));

    // Check if email is valid before Firebase request
    if (email.length > 5 && email.includes("@")) {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(email)
        .then((signInMethods) => {
          setEmailExists(signInMethods.length > 0);
        })
        .catch((error) => {
          console.error("Error checking email: ", error);
        });
    }
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const theme = useTheme();

  const isDarkTheme = (primaryColor) => {
    const rgb = parseInt(primaryColor.substring(1), 16); // Remove # and parse as hex
    const r = (rgb >> 16) & 0xff; // Red
    const g = (rgb >> 8) & 0xff; // Green
    const b = (rgb >> 0) & 0xff; // Blue
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Relative luminance formula
    return luminance < 128; // Dark if luminance is less than 128
  };

  const isDark = isDarkTheme(theme.palette.primary.main);

  const textColor = isDark ? "white" : "#464646";

  return (
    <>
      <Grid
        container
        className={classes.makeMiddle}
        style={{ padding: "12px 16px" }}
      >
        <Grid item xs={12}>
          <TextFieldDefault
            id="email"
            name="email"
            label="Email Address"
            value={customer.email}
            variant="filled"
            type="email"
            onChange={handleChangeEmail}
            fullWidth
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 80,
            })}
            InputLabelProps={{
              style: { color: textColor },
            }}
            InputProps={{
              style: { color: textColor },
            }}
            helperText={
              emailExists ? (
                <div style={{ display: "flex" }}>
                  <Typography
                    variant="body1"
                    style={{
                      color: "red",
                      paddingRight: "8px",
                      alignItems: "center",
                    }}
                  >
                    This email is already registered.
                  </Typography>
                  <Typography
                    style={{ cursor: "pointer", fontWeight: "bold" }}
                    onClick={handleChangeLoginStatus}
                  >
                    Login
                  </Typography>
                </div>
              ) : errors.email ? (
                "Please enter Email Address"
              ) : (
                ""
              )
            }
            error={errors.email?.type}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldDefault
            id="firstName"
            name="firstName"
            label="First Name"
            value={customer.firstName}
            variant="filled"
            onChange={handleChange}
            fullWidth
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 80,
            })}
            InputLabelProps={{
              style: { color: textColor },
            }}
            InputProps={{
              style: { color: textColor },
            }}
            helperText={errors.firstName ? "Please enter First Name" : ""}
            error={errors.firstName?.type}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldDefault
            id="lastName"
            name="lastName"
            label="Last Name"
            value={customer.lastName}
            variant="filled"
            onChange={handleChange}
            fullWidth
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 80,
            })}
            InputLabelProps={{
              style: { color: textColor },
            }}
            InputProps={{
              style: { color: textColor },
            }}
            helperText={errors.lastName ? "Please enter Last Name" : ""}
            error={errors.lastName?.type}
          />
        </Grid>

        <Grid item xs={12}>
          <TextFieldDefault
            id="password"
            name="password"
            label="Password"
            value={customer.password}
            variant="filled"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            fullWidth
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 80,
            })}
            InputLabelProps={{
              style: { color: textColor },
            }}
            helperText={errors.password ? "Please enter Password" : ""}
            error={errors.password?.type}
            InputProps={{
              style: { color: textColor },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldDefault
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            value={customer.confirmPassword}
            variant="filled"
            onChange={handleChange}
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 80,
              validate: (value) =>
                value === customer.password || "Passwords do not match",
            })}
            InputLabelProps={{
              style: { color: textColor },
            }}
            helperText={errors.confirmPassword ? "Password mismatch" : ""}
            error={errors.confirmPassword?.type}
            InputProps={{
              style: { color: textColor },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={toggleConfirmPasswordVisibility}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <StickyButton
            flex={2}
            style={{
              width: "100%",
              marginTop: "12px",
              marginBottom: "12px",
              height: "40px",
            }}
            onClick={handleContinue}
            disabled={isLoading || isAuthenticated}
          >
            {isLoading ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              "Register"
            )}
          </StickyButton>
        </Grid>
        <Grid item xs={12}>
          <Grid container style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={3} sm={4}>
              <Divider
                style={{
                  margin: "4px 0",
                  backgroundColor: theme.palette.primary.contrastText,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <Typography className={classes.textStyle}>
                or Register with
              </Typography>
            </Grid>
            <Grid item xs={3} sm={4}>
              <Divider
                style={{
                  margin: "4px 0",
                  backgroundColor: theme.palette.primary.contrastText,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "18px",
              }}
            >
              Don't have an account?
            </Typography>
            <Button
              onClick={handleChangeLoginStatus}
              style={{ textTransform: "none" }}
              disabled={isAuthenticated}
            >
              <Typography
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingLeft: "8px",
                }}
              >
                Login
              </Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

RegistrationPageNew.propTypes = {};
RegistrationPageNew.defaultProps = {};

export default RegistrationPageNew;
