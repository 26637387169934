import { isFunction } from "lodash";
import { loginUserSuccess, setIsOpenLoginModal } from "../redux/actions/auth";
import { setLocalStore } from "./utils";
import { fetchLoyaltyListAsync } from "../components/@pages/storeHome/CatMenu/redux/actions";

export const updateLocalStorage = (
  token,
  dispatch,
  firebaseUser,
  history,
  params,
  location,
) => {
  dispatch(setIsOpenLoginModal(false));
  setLocalStore("idToken", token);
  dispatch(fetchLoyaltyListAsync(params.locationId, true));

  dispatch(
    loginUserSuccess(
      `${firebaseUser.firstName} ${firebaseUser.lastName}`,
      firebaseUser.email,
    ),
  );

  setLocalStore("userId", firebaseUser.uid);
  setLocalStore("photoURL", firebaseUser?.photoURL);

  setTimeout(() => {
    if (window.authCallback && isFunction(window.authCallback)) {
      window.authCallback();
      window.authCallback = null;
    }

    history.push({
      pathname: `/location/${params.locationId}/shop`,
      search: location.search,
    });
  }, 1000);
};
