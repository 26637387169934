import {

  FETCH_OPEN_TIMES_ERROR,
  FETCH_OPEN_TIMES_PENDING,
  FETCH_OPEN_TIMES_SUCCESS, FETCH_ORDER_RELATED_INFO_ERROR,
  FETCH_ORDER_RELATED_INFO_PENDING,
  FETCH_ORDER_RELATED_INFO_SUCCESS,

  FETCH_STORE_INFO_ERROR,
  FETCH_STORE_INFO_PENDING,
  FETCH_STORE_INFO_SUCCESS,

  SET_EXTRA_CHARGES,
  SET_IS_OPEN_NOW,
  SET_OTHER_STORE_INFO,
} from "./actions";

export const StoreInitialState = {
  deliveryInfo: [],
  isFetchingDeliveryInfo: false,
  errorDeliveryInfo: null,

  openTimes: {},
  isOpenNow: true,
  isFetchingOpenTimes: false,
  errorOpenTimes: null,

  storeInfo: {},
  isFetchingStoreInfo: false,
  errorStoreInfo: null,

  discountInfo: {},
  isFetchingDiscountInfo: false,
  errorDiscountInfo: null,

  isOrderingActive: true,
  isPreOrderActive: true,
  serviceCharge: "",
  carrierBagCharge: "",
  scheduleDelay: 0,
  extraCharges: {},
};

export default function StoreReducer(state = StoreInitialState, action) {
  switch (action.type) {
    // ORDER RELATED (DELIVERY AND DISCOUNTS)
    case FETCH_ORDER_RELATED_INFO_PENDING:
      return {
        ...state,
        isFetchingDeliveryInfo: true,
        isFetchingDiscountInfo: true,
      };

    case FETCH_ORDER_RELATED_INFO_SUCCESS:
      return {
        ...state,
        deliveryInfo: action.deliveryInfo,
        isFetchingDeliveryInfo: false,

        discountInfo: action.discountInfo,
        isFetchingDiscountInfo: false,
      };

    case FETCH_ORDER_RELATED_INFO_ERROR:
      return {
        ...state,
        errorDeliveryInfo: action.error,
        isFetchingDeliveryInfo: false,

        errorDiscountInfo: action.error,
        isFetchingDiscountInfo: false,
      };

    // OPEN TIMES
    case FETCH_OPEN_TIMES_PENDING:
      return {
        ...state,
        isFetchingOpenTimes: true,
      };

    case FETCH_OPEN_TIMES_SUCCESS:
      return {
        ...state,
        openTimes: action.payload,
        isFetchingOpenTimes: false,
      };

    case FETCH_OPEN_TIMES_ERROR:
      return {
        ...state,
        errorOpenTimes: action.error,
        isFetchingOpenTimes: false,
      };

    // STORE INFO
    case FETCH_STORE_INFO_PENDING:
      return {
        ...state,
        isFetchingStoreInfo: true,
      };

    case FETCH_STORE_INFO_SUCCESS:
      return {
        ...state,
        storeInfo: action.payload,
        isFetchingStoreInfo: false,
      };

    case FETCH_STORE_INFO_ERROR:
      return {
        ...state,
        errorStoreInfo: action.error,
        isFetchingStoreInfo: false,
      };
    case SET_IS_OPEN_NOW:
      return {
        ...state,
        isOpenNow: action.isOpen,
      };

    case SET_OTHER_STORE_INFO:
      return {
        ...state,
        isOrderingActive: action.otherInfo.isOrderingActive,
        isPreOrderActive: action.otherInfo.isPreOrderActive,
        serviceCharge: action.otherInfo.serviceCharge,
        carrierBagCharge: action.otherInfo.carrierBagCharge,
        scheduleDelay: action.otherInfo.scheduleDelay,
      };
    case SET_EXTRA_CHARGES:
      return {
        ...state,
        extraCharges: { ...state.extraCharges, ...action.charges },
      };

    default:
      return state;
  }
}
