import React, { useEffect, useState } from "react";

import {
  Dialog,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenLoginModal } from "../../../../redux/actions/auth";
import RegistrationPageNew from "./RegistrationPageNew";
import PasswordResetModal from "../../signInPage/SignInPage/PasswordResetModal";
import UserDetailsModal from "./UserDetailsModal";
import AlertMessage from "../../../common/AlertMessage";
import SignInPageNew from "./SignInPageNew";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    "& .MuiDialog-paperFullWidth": {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: props.popupBackgroundColor,
    },
    "& .MuiDialog-container": {
      [theme.breakpoints.up("md")]: {
        height: theme?.view?.isFixedList ? "initial" : "100%",
      },
    },
  }),
  titleSection: {
    padding: "0px 16px",
    textAlign: "center",
  },
}));

const LoginDialog = () => {
  const [authError, setAuthError] = useState("");
  const [isLoginOrRegister, setIsLoginOrRegister] = useState(true);
  const [isOpenPasswordResetNodal, setIsOpenPasswordResetNodal] =
    useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [
    isOpenEmailVerificationLoadingModal,
    setIsOpenEmailVerificationLoadingModal,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const dispatch = useDispatch();
  const { isAuthenticated, isOpenLoginModal } = useSelector(
    (state) => state.auth,
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  useEffect(() => {
    setAuthError("");
    setIsLoginOrRegister(true);
  }, [isOpenLoginModal]);

  const handleClose = (event, reason) => {
    if (
      (reason === "backdropClick" || reason === "escapeKeyDown") &&
      isOpenEmailVerificationLoadingModal
    ) {
      return; // Disable closing the dialog via backdrop or Escape key
    }

    dispatch(setIsOpenLoginModal(false));
  };

  const handleChangeLoginStatus = () => {
    setIsLoginOrRegister(!isLoginOrRegister);
  };

  // Utility to calculate luminance and determine theme
  const isDarkTheme = (primaryColor) => {
    const rgb = parseInt(primaryColor.substring(1), 16); // Remove # and parse as hex
    const r = (rgb >> 16) & 0xff; // Red
    const g = (rgb >> 8) & 0xff; // Green
    const b = (rgb >> 0) & 0xff; // Blue
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Relative luminance formula
    return luminance < 128; // Dark if luminance is less than 128
  };

  const isDark = isDarkTheme(theme.palette.primary.main);
  const popupBackgroundColor = isDark ? "#212121" : "#FFFFFF";
  const popupTextColor = isDark ? "#FFFFFF" : "#808080";

  const classes = useStyles({ popupBackgroundColor, popupTextColor });

  return (
    <>
      <Dialog
        classes={{ root: classes.root }}
        open={isOpenLoginModal}
        fullScreen={isMobile} // Fullscreen on mobile
        maxWidth={isMobile ? false : "sm"} // Disable maxWidth on mobile
        fullWidth
        onClose={handleClose}
        aria-labelledby="login-dialog"
        aria-describedby="login-dialog-content"
      >
          <div style={{ display: "flex", justifyContent: "end", padding: "8px 16px " }}>
            <IconButton
              aria-label="close"
              onClick={() => dispatch(setIsOpenLoginModal(false))}
            >
              <CloseIcon />
            </IconButton>
          </div>
        <div className={!isAuthenticated && classes.titleSection}>
          {!isAuthenticated && !isOpenEmailVerificationLoadingModal && (
            // <Typography variant="h5">Come Here Often?</Typography>
            <Typography variant="h5">
              {isLoginOrRegister ? "Welcome Back!" : "Join Us Today!"}
            </Typography>
          )}
          {!isAuthenticated && !isOpenEmailVerificationLoadingModal && (
            // <Typography variant="subtitle1">
            //   Connect with us for fast checkout
            // </Typography>
            <Typography variant="subtitle1">
              {isLoginOrRegister
                ? "Log in to your account for faster checkout."
                : "Create an account to enjoy faster checkout and earn exclusive rewards!"}
            </Typography>
          )}
          {/* {isAuthenticated && !isOpenEmailVerificationLoadingModal && (
            <>
              <Typography variant="h5">Hello {displayName}</Typography>
              <Typography variant="subtitle1">
                Want to login as a different user ?
              </Typography>
              <ButtonContained height="40px" onClick={handleLogout}>
                Log me out
              </ButtonContained>
            </>
          )} */}
        </div>
        <div style={{ padding: isAuthenticated ? 0 : 0 }}>
          <div style={{ padding: "4px 0" }}>
            {authError && !isOpenEmailVerificationLoadingModal && (
              <MuiAlert severity="error" elevation={6} variant="filled">
                {authError}
              </MuiAlert>
            )}
          </div>

          {/* {isLoginOrRegister &&
         
              <SignInPage
                setIsOpenPasswordResetNodal={setIsOpenPasswordResetNodal}
                handleChangeLoginStatus={handleChangeLoginStatus}
                authError={authError}
                setAuthError={setAuthError}
                setIsOpenEmailVerificationLoadingModal={
                  setIsOpenEmailVerificationLoadingModal
                }
                setCustomerDetails={setCustomerDetails}
                isOpenEmailVerificationLoadingModal={
                  isOpenEmailVerificationLoadingModal
                }
                customerDetails={customerDetails}
              />
            } */}

          {isLoginOrRegister &&
            (isAuthenticated ? (
              <UserDetailsModal />
            ) : (
              <SignInPageNew
                setIsOpenPasswordResetNodal={setIsOpenPasswordResetNodal}
                handleChangeLoginStatus={handleChangeLoginStatus}
                setAuthError={setAuthError}
                setIsOpenEmailVerificationLoadingModal={
                  setIsOpenEmailVerificationLoadingModal
                }
                setCustomerDetails={setCustomerDetails}
                isOpenEmailVerificationLoadingModal={
                  isOpenEmailVerificationLoadingModal
                }
                customerDetails={customerDetails}
              />
              // <SignInPage
              //   setIsOpenPasswordResetNodal={setIsOpenPasswordResetNodal}
              //   handleChangeLoginStatus={handleChangeLoginStatus}
              //   setAuthError={setAuthError}
              //   setIsOpenEmailVerificationLoadingModal={
              //     setIsOpenEmailVerificationLoadingModal
              //   }
              //   setCustomerDetails={setCustomerDetails}
              //   isOpenEmailVerificationLoadingModal={
              //     isOpenEmailVerificationLoadingModal
              //   }
              //   customerDetails={customerDetails}
              // />
            ))}

          {!isLoginOrRegister && (
            <RegistrationPageNew
              handleChangeLoginStatus={handleChangeLoginStatus}
              setAuthError={setAuthError}
              setCustomerDetails={setCustomerDetails}
            />
          )}

          <span style={{ margin: 8 }} />
        </div>
      </Dialog>

      <PasswordResetModal
        setOpenDialog={setIsOpenPasswordResetNodal}
        openDialog={isOpenPasswordResetNodal}
        setIsLoading={setIsLoading}
        setError={setAuthError}
        isLoading={isLoading}
        setAlertMessage={setAlertMessage}
      />

      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
        anchorOrigin="bottom"
      />
    </>
  );
};

LoginDialog.propTypes = {};
LoginDialog.defaultProps = {};

export default LoginDialog;
