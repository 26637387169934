import {
  getLocationInfo,
  getLocationOpeningTimes,
  getLocationOrderRelatedInfo,
} from "../../../../services/locationService";
import { logError, logInfo } from "../../../../services/logging";
import { setLocalStore } from "../../../../utils/storage/localStorage";
import { setDeliveryData, setOpenTimes } from "../MyOrder/redux/actions";

// delivery info
export const FETCH_ORDER_RELATED_INFO_PENDING =
  "FETCH_ORDER_RELATED_INFO_PENDING";
export const FETCH_ORDER_RELATED_INFO_SUCCESS =
  "FETCH_ORDER_RELATED_INFO_SUCCESS";
export const FETCH_ORDER_RELATED_INFO_ERROR = "FETCH_ORDER_RELATED_INFO_ERROR";

// open times
export const FETCH_OPEN_TIMES_PENDING = "FETCH_OPEN_TIMES_PENDING";
export const FETCH_OPEN_TIMES_SUCCESS = "FETCH_OPEN_TIMES_SUCCESS";
export const FETCH_OPEN_TIMES_ERROR = "FETCH_OPEN_TIMES_ERROR";

// store info
export const FETCH_STORE_INFO_PENDING = "FETCH_STORE_INFO_PENDING";
export const FETCH_STORE_INFO_SUCCESS = "FETCH_STORE_INFO_SUCCESS";
export const FETCH_STORE_INFO_ERROR = "FETCH_STORE_INFO_ERROR";

// discount info
export const FETCH_DISCOUNT_INFO_PENDING = "FETCH_DISCOUNT_INFO_PENDING";
export const FETCH_DISCOUNT_INFO_SUCCESS = "FETCH_DISCOUNT_INFO_SUCCESS";
export const FETCH_DISCOUNT_INFO_ERROR = "FETCH_DISCOUNT_INFO_ERROR";
export const SET_IS_OPEN_NOW = "SET_IS_OPEN_NOW";
export const SET_OTHER_STORE_INFO = "SET_OTHER_STORE_INFO";

export const SET_EXTRA_CHARGES = "SET_EXTRA_CHARGES";

// delivery info
function fetchOrderRelatedInfoPending() {
  return {
    type: FETCH_ORDER_RELATED_INFO_PENDING,
  };
}

function fetchOrderRelatedInfoSuccess(deliveryInfo, discountInfo) {
  return {
    type: FETCH_ORDER_RELATED_INFO_SUCCESS,
    discountInfo,
    deliveryInfo,
  };
}

function fetchOrderRelatedInfoError(error) {
  return {
    type: FETCH_ORDER_RELATED_INFO_ERROR,
    error,
  };
}

export function setIsOpenNow(isOpen) {
  return {
    type: SET_IS_OPEN_NOW,
    isOpen,
  };
}

export function setOtherStoreInfo(otherInfo) {
  return {
    type: SET_OTHER_STORE_INFO,
    otherInfo,
  };
}

export function setExtraCharges(charges) {
  return {
    type: SET_EXTRA_CHARGES,
    charges,
  };
}

export const asyncFetchOrderRelatedInfo = (locationId) => async (dispatch) => {
  dispatch(fetchOrderRelatedInfoPending());
  try {
    const res = await getLocationOrderRelatedInfo(locationId);
    const { deliveryInfo, discount } = res.data.data;
    dispatch(setDeliveryData(deliveryInfo));
    dispatch(fetchOrderRelatedInfoSuccess(deliveryInfo, discount));
    logInfo({
      message: "Delivery info and discounts",
    });
  } catch (error) {
    logError({ message: "Error fetching Delivery info and discounts", error });
    dispatch(fetchOrderRelatedInfoError("Error fetch delivery info"));
  }
};

// open times
function fetchOpenTimesPending() {
  return {
    type: FETCH_OPEN_TIMES_PENDING,
  };
}

function fetchOpenTimesSuccess(openTimes) {
  return {
    type: FETCH_OPEN_TIMES_SUCCESS,
    payload: openTimes,
  };
}

function fetchOpenTimesError(error) {
  return {
    type: FETCH_OPEN_TIMES_ERROR,
    error,
  };
}

export const asyncFetchOpenTimes = (locationId) => async (dispatch) => {
  dispatch(fetchOpenTimesPending());
  try {
    const res = await getLocationOpeningTimes(locationId);
    dispatch(setOpenTimes(res.data.data));
    dispatch(fetchOpenTimesSuccess(res.data.data));
    logInfo({ message: "Opening times", openingTimes: res?.data?.data });
  } catch (error) {
    dispatch(fetchOpenTimesError("Error fetch open times"));
    logError({ message: "Error fetching opening times: ", error });
  }
};

// store info
function fetchStoreInfoPending() {
  return {
    type: FETCH_STORE_INFO_PENDING,
  };
}

function fetchStoreInfoSuccess(deliveryInfo) {
  return {
    type: FETCH_STORE_INFO_SUCCESS,
    payload: deliveryInfo,
  };
}

function fetchStoreInfoError(error) {
  return {
    type: FETCH_STORE_INFO_ERROR,
    error,
  };
}

export const asyncFetchStoreInfo = (locationId) => async (dispatch) => {
  dispatch(fetchStoreInfoPending());
  try {
    const res = await getLocationInfo(locationId);
    setLocalStore("addressFormatted", res.data.data.addressFormatted);
    dispatch(fetchStoreInfoSuccess(res.data.data));
  } catch (error) {
    dispatch(fetchStoreInfoError("Error fetch store info"));
  }
};
