import React from "react";

import { makeStyles, Typography } from "@material-ui/core";
import _ from "lodash";
import { useSelector } from "react-redux";

import moment from "../../../../../utils/time/moment";
import { convertDayCodeToNames } from "../../../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  OpenTimeListWrapper: {
    marginTop: 8,
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primaryTextColor,
    padding: 8,
  },
}));

const OpenTimeList = ({ selectedDateTime, scheduleDelay }) => {
  const classes = useStyles();

  const { openTimes } = useSelector((state) => state.store);
  const day = moment(selectedDateTime).format("ddd").toLowerCase();

  const dayOpenTimes = openTimes?.weekly?.filter(
    (time) =>
      (time.day.includes("we") && day.includes("we")) || time.day === day,
  );

  const addMinutes = (time) => {
    const [hours, minutes] = time.split(":").map((num) => parseInt(num, 10));
    // Create a new Date object with a valid date
    let date = new Date();
    date.setHours(hours, minutes); // Set the hours and minutes of the date object
    date.setMinutes(date.getMinutes() + (scheduleDelay || 0)); // Add the delay

    // Format the new time as HH:mm
    const updatedTime = date.toTimeString().slice(0, 5); // Get the first 5 characters (HH:mm)

    return updatedTime;
  };

  return (
    <div className={classes.OpenTimeListWrapper}>
      <Typography variant="subtitle2">
        {!_.isEmpty(dayOpenTimes)
          ? dayOpenTimes.map((node, i) => (
              <div
                key={String(i)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 8,
                }}
              >
                <span>{convertDayCodeToNames(node.day)}</span>{" "}
                <span>
                  &nbsp;
                  {addMinutes(node.openTime)}
                  {" - "}
                  {node.closeTime}
                </span>
              </div>
            ))
          : `Sorry we are closed on ${convertDayCodeToNames(day)}`}
      </Typography>
    </div>
  );
};

OpenTimeList.propTypes = {};
OpenTimeList.defaultProps = {};

export default OpenTimeList;
