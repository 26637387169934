import React from "react";

import { makeStyles } from "@material-ui/core";

import { STICKY_BUTTON_HEIGHT } from "../../components/common/buttons/StickyButton";

export const HEADER_HEIGHT = 57;
const CONTENT_SECTION_PADDING = 32;

const VIEWPORT_OFFSET = HEADER_HEIGHT + CONTENT_SECTION_PADDING + STICKY_BUTTON_HEIGHT;

const useStyles = makeStyles((theme) => ({
  ContentContainerWrapper: {
    padding: (props) => (props.noTopPadding ? "4px 16px 16px 16px": props.noPadding ? 0 : 16),
    // flex: 1,
    // marginBottom: (props) => (props.isOrderStatusPage ? 0 : 16),
    [theme.breakpoints.down("xs")]: {
      height: 1200,
      maxHeight: (props) => (props.isOrderStatusPage
        ? `calc(100vh - ${STICKY_BUTTON_HEIGHT}px)`
        : `calc(100vh - ${VIEWPORT_OFFSET}px)`),
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 500,
    },
    overflowY: "auto",
    overflowX: "hidden",
    // overflowY: (props) => (props.overFlowUnset ? "unset" : "auto"),
    // overflowX: (props) => (props.overFlowUnset ? "unset" : "hidden"),
  },
}));

const ContentContainer = ({ children, noPadding, isOrderStatusPage, noTopPadding }) => {
  const classes = useStyles({ noPadding, isOrderStatusPage, noTopPadding });
  return <div className={classes.ContentContainerWrapper}>{children}</div>;
};

ContentContainer.propTypes = {};
ContentContainer.defaultProps = {};

export default ContentContainer;
